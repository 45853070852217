import React, { useEffect, useState } from "react";
import axios from "axios";
import Container from "../Container";
import SectionTitle from "./SectionTitle";

export default function UpcomingEvents() {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    // Fetch the event data from the API
    axios.get("http://localhost:3000/events/latest/upcoming")
      .then(response => {
        setCards(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the event data!", error);
      });
  }, []);

  return (
    <section className="my-14">
      <Container>
        <div>
          <SectionTitle title="UPCOMING INTERNATIONAL CONFERENCES 2024" classes="text-center" />
          <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 items-start gap-6 mt-8">
            {cards.map((card) => (
              <div
                className="flex flex-col h-full md:min-h-[60vh] w-[320px] mx-auto px-4 pt-4 pb-6 rounded-lg shadow-lg border border-gray-200 relative z-10 bg-white transition-transform transform hover:scale-105"
                key={card.event_id}
              >
                <div className="relative rounded-xl overflow-hidden flex-shrink-0">
                  <img src={card.image_url} alt={card.event_name} className="w-full h-40 object-cover" />
                </div>
                <article className="flex flex-col flex-grow px-2 mt-4">
                  <div className="flex items-center mb-2">
                    <span className="text-red-500 mr-2">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M5.05 4.05a7 7 0 0110.9 0l.272.272a7 7 0 010 9.9l-5.657 5.657a.75.75 0 01-1.06 0L4.777 14.22a7 7 0 01-.727-9.9l.272-.272zm9.172 1.414a5.5 5.5 0 00-7.778 0l-.272.272a5.5 5.5 0 000 7.778L10 17.778l3.828-3.828a5.5 5.5 0 000-7.778l-.273-.272z" clipRule="evenodd" />
                      </svg>
                    </span>
                    <h3 className="text-lg font-semibold">{`${card.city}, ${card.country}`}</h3>
                  </div>
                  <h2 className="font-bold text-xl hover:text-[#6D9886] transition-colors cursor-pointer mb-4">
                    {card.event_name}
                  </h2>
                  <p className="text-sm mb-4">
                    {card.short_desc.slice(0, 50)}...
                  </p>
                  <button className="mt-auto block mx-auto text-[#6D9886]">
                    Read more
                  </button>
                </article>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
}

import SearchBar from "./SearchBar";

export default function Front() {


  return (
    <section className="z-10">
      <div className="sm:w-11/12 mx-auto">
        <div
          className={`py-8 sm:rounded-3xl relative w-full h-[620px] bg-homefrontbg bg-cover lg:bg-center bg-no-repeat bg-left`}
        >
          <div className="absolute bottom-[24%] sm:left-[6%] left-[5%] flex items-center flex-wrap gap-4">

            <form className="flex items-end gap-4 flex-wrap">
              <SearchBar />
              <button className="bg-white px-2 py-3 w-24 font-bold uppercase text-sm text-black hover:text-white hover:bg-black hover:bg-opacity-50 rounded-md">
                Search
              </button>
            </form>
          </div>
        
        </div>
      </div>
    </section>
  );
}

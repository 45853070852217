import Discover from "../components/Home/Discover";
import Front from "../components/Home/Front";
import Funds from "../components/Home/Funds";
import HowItWorks from "../components/Home/HowItWorks";
import UpcomingEvents from "../components/Home/UpcomingEvents";
import Quiz from "../components/Home/Quiz";
import Stats from "../components/Home/Stats";
import Stories from "../components/Home/Stories";
import Trusted from "../components/Home/Trusted";
import About from "../components/Home/About";
import Explore from "../components/Home/Explore";

export default function Home() {
  return (
    <main>
      <Front />
      <About />
      <UpcomingEvents />
      {/* <Explore /> */}

      {/* <Stats /> */}
      {/* <Funds /> */}
      {/* <Quiz /> */}
      {/* <Discover /> */}
      {/* <HowItWorks /> */}
      {/* <Stories /> */}
      {/* <Trusted /> */}
    </main>
  );
}

import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import EventDetails from "./Pages/EventDetail";
import EventForm from "./components/Event/AddEvent";
import SubscribeForm from "./components/Subscribe/SubscibeForm";

export default function MyRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/eventdetail" element={<EventDetails />} />
      <Route path="/create_event" element={<EventForm />} />
      <Route path="/subscribe" element={<SubscribeForm />} />

    </Routes>
  );
}

import React from 'react';
import { FaCalendarAlt, FaMapMarkerAlt, FaEnvelope } from 'react-icons/fa';

const EventDetails = () => {
  const event = {
    event_id: 243139,
    org_id: 1,
    event_topic: "6###84###Multidisciplinary Studies",
    event_type: "5",
    event_name: "12th International Conference on Multidisciplinary Research",
    country: "243#Hong Kong",
    state: "",
    city: "Hong Kong",
    org_society: "InterGlobe Research Network",
    contact_person_event: "Conference Co-ordinater",
    event_enq_email: "igrnetconference@gmail.com",
    web_url: "http://www.icmr.igrnet.org/333/hong-kong/",
    event_stat: "2023-12-05T18:30:00.000Z",
    event_end: "2023-12-06T18:30:00.000Z",
    abstract_deadline: "2023-11-11T18:30:00.000Z",
    short_desc: "IGRNET aims to provide an opportunity to academics and practitioners around the world to discuss and share the contemporary issues of business and social science research. The proposed conference provides an outlet for high quality research in all areas of accounting, CSR, finance, economics, econometrics, education, information technology, Islamic finance and Sharia, law, management, marketing, social business and multidisciplinary.\r\nAim of our peer-reviewed conference is to bring together the world’s leading scholars and opinion leaders and to increase our understanding of emerging scientific issues and research methodologies relating to business management, economic and social sciences. Please, engage freely in discussions, share your ideas and build relationships among the group of international researchers.",
    keywords: "Multidisciplinary Studies##",
    date_post: "2019-12-26T18:30:00.000Z",
    status: "Accept",
    message: null,
    reason: null,
    reg_counter: null,
    venue: "",
    image_url: "https://kalapeet.com/wp-content/uploads/2021/04/corporate-eents.jpg"
  };

  return (
    <div className="max-w-4xl mx-auto p-4 mt-6 mb-6">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <img className="w-full h-64 md:h-80  object-cover" src={event.image_url} alt={event.event_name} />
        <div className="p-6">
          <h2 className="text-2xl font-bold mb-2">{event.event_name}</h2>
          <p className="text-gray-700 mb-4">{event.short_desc}</p>
          <div className="flex items-center text-gray-700 mb-2">
            <FaCalendarAlt className="mr-2" />
            <span>{new Date(event.event_stat).toLocaleDateString()} - {new Date(event.event_end).toLocaleDateString()}</span>
          </div>
          <div className="flex items-center text-gray-700 mb-2">
            <FaMapMarkerAlt className="mr-2" />
            <span>{event.city}, {event.country.split('#')[1]}</span>
          </div>
          <div className="flex items-center text-gray-700 mb-2">
            <FaEnvelope className="mr-2" />
            <span>{event.event_enq_email}</span>
          </div>
          <div className="flex items-center text-gray-700 mb-2">
            <span className="font-bold mr-2">Organizer:</span>
            <span>{event.org_society}</span>
          </div>
          <div className="mt-4">
            <a href={event.web_url} className="text-blue-500 hover:underline">Event Website</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;

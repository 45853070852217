import { logo_2 } from "../assets/home";
import Container from "./Container";

export default function Footer() {
  return (
    <footer className="bg-gray-900 pt-12 pb-2">
      <Container>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8">
          <div className="flex items-center">
            <img src={'https://www.conferencealerts.org/images/logo.webp'} alt="Grant canyon" className="w-60 h-24 p-2 bg-white rounded-lg" />
          </div>
          <div>
            <h3 className="text-xl font-semibold text-white mb-4">Company</h3>
            <ul className="space-y-2">
              <li>
                <a href="#" className="text-gray-400 hover:text-white transition duration-150">Contact</a>
              </li>
              <li>
                <a href="#" className="text-gray-400 hover:text-white transition duration-150">Blog</a>
              </li>
              <li>
                <a href="#" className="text-gray-400 hover:text-white transition duration-150">Privacy</a>
              </li>
              <li>
                <a href="#" className="text-gray-400 hover:text-white transition duration-150">SiteMap</a>
              </li>
              <li>
                <a href="#" className="text-gray-400 hover:text-white transition duration-150">How To Use Our Site</a>
              </li>
              <li>
                <a href="#" className="text-gray-400 hover:text-white transition duration-150">Promote Event</a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-semibold text-white mb-4">Subscribe</h3>
            <div className="text-gray-400">
              <p>Join our newsletter to get the latest updates!</p>
              <form className="mt-4 flex">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="px-4 py-2 bg-gray-800 text-white rounded-l focus:outline-none"
                />
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded-r transition duration-150 hover:bg-blue-600"
                >
                  Subscribe
                </button>
              </form>
            </div>
          </div>
        </div>
      </Container>
      <div>
        <p className="text-gray-400 text-center mt-2">
          &copy; {new Date().getFullYear()} All Rights Reserved. <a className="text-blue-400" href="/">Conferencealerts.org</a>
        </p>
      </div>
    </footer>
  );
}